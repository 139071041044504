// src/components/RedirectToSlug.js

import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const RedirectToSlug = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://192.168.1.85:4000';

  useEffect(() => {
    const fetchSlug = async () => {
      try {
        const response = await fetch(`${backendURL}/api/products/id/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch slug');
        }
        const product = await response.json();
        navigate(`/shop/${product.slug}`, { replace: true });
      } catch (error) {
        console.error('Error fetching slug:', error);
        navigate('/not-found', { replace: true });
      }
    };

    fetchSlug();
  }, [id, navigate, backendURL]);

  return <p>Redirecting...</p>;
};

export default RedirectToSlug;
