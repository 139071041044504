// src/components/Footer.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Ensure the CSS file is correctly imported

const Footer = () => {
  const [recentPosts, setRecentPosts] = useState([]);
  const [error, setError] = useState(null); // Error state for fetching posts
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (!backendURL) {
      console.error('REACT_APP_BACKEND_URL is not defined in environment variables.');
      setError('Configuration error. Please try again later.');
      setIsLoading(false);
      return;
    }

    // Fetch recent blog posts from your backend API
    fetch(`${backendURL}/api/blog/posts?limit=3`) // Corrected fetch URL
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch recent posts');
        }
        return response.json();
      })
      .then(data => {
        setRecentPosts(data.posts);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching recent posts:', error);
        setError('Failed to load recent posts.');
        setIsLoading(false);
      });
  }, [backendURL]); // Added backendURL to dependencies

  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="footer-main">
          {/* Main Pages */}
          <div className="footer-section main-pages">
            <h4>Main Pages</h4>
            <ul className="footer-links">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about-us">About Us</Link></li>
              <li><Link to="/shop">Shop</Link></li>
              <li><Link to="/cart">Cart</Link></li>
              <li><Link to="/blog">Blog</Link></li>
              {/* Add more main page links as needed */}
            </ul>
          </div>

          {/* Policies */}
          <div className="footer-section policies">
            <h4>Policies</h4>
            <ul className="footer-links">
              <li><Link to="/policy/privacy">Privacy Policy</Link></li>
              <li><Link to="/policy/terms">Terms of Service</Link></li>
              <li><Link to="/policy/refund">Refund Policy</Link></li>
              <li><Link to="/policy/cookies">Cookie Policy</Link></li>
            </ul>
          </div>

          {/* Contact */}
          <div className="footer-section contact">
            <h4>Contact</h4>
            <ul className="footer-links">
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>

          {/* Recent Blog Posts */}
          <div className="footer-recent-posts">
            <h4>Recent Posts</h4>
            {isLoading ? (
              <div className="footer-recent-skeleton">
                {[...Array(3)].map((_, index) => (
                  <div key={index} className="skeleton-post">
                    <div className="skeleton-image"></div>
                    <div className="skeleton-text"></div>
                  </div>
                ))}
              </div>
            ) : error ? (
              <p className="footer-error">{error}</p>
            ) : (
              <ul className="footer-recent-links">
                {recentPosts.map(post => (
                  <li key={post.id} className="footer-recent-post">
                    {post.image_url && (
                      <img
                        src={`${backendURL}${post.image_url}`} // Updated to use backendURL
                        alt={`Thumbnail for ${post.title}`}
                        className="footer-recent-image"
                        width="40" // Define exact width
                        height="40" // Define exact height
                      />
                    )}
                    <div className="footer-recent-text">
                      <Link to={`/blog/${post.id}`}>{post.title}</Link>
                      <span className="footer-post-date">{new Date(post.date_published).toLocaleDateString()}</span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* Company Information */}
        <div className="footer-company-info">
          <h4>Bright Happy Books</h4>
          <p>&copy; {new Date().getFullYear()} Bright Happy Books. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
