// src/components/CartContext.js

import React, { createContext, useContext, useReducer, useEffect } from 'react';

// Create the Cart Context
const CartContext = createContext();

// Initial State
const initialState = {
  cart: [],
};

// Reducer Function
const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const { id, type, name, price, image_url, download_url } = action.payload;
      // Find if the item already exists in the cart
      const existingItem = state.cart.find(item => item.id === id && item.type === type);
      if (existingItem) {
        // Increment quantity if item exists
        return {
          ...state,
          cart: state.cart.map(item =>
            item.id === id && item.type === type
              ? { ...item, quantity: item.quantity + 1 }
              : item
          ),
        };
      } else {
        // Add new item to cart with price converted to number
        return {
          ...state,
          cart: [...state.cart, { id, type, name, price: Number(price), image_url, download_url, quantity: 1 }],
        };
      }

    case 'REMOVE_FROM_CART':
      const { id: removeId, type: removeType } = action.payload;
      return {
        ...state,
        cart: state.cart.filter(item => !(item.id === removeId && item.type === removeType)),
      };

    case 'CLEAR_CART':
      return initialState;

    default:
      return state;
  }
};

// Cart Provider Component
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState, () => {
    try {
      const localData = localStorage.getItem('cart');
      if (localData) {
        const parsedData = JSON.parse(localData);
        // Check if parsedData has the cart property
        if (parsedData && Array.isArray(parsedData.cart)) {
          const updatedCart = parsedData.cart.map(item => ({
            ...item,
            price: Number(item.price),
          }));
          return { cart: updatedCart };
        }
      }
      return initialState;
    } catch (error) {
      return initialState;
    }
  });

  // Sync cart state with localStorage
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(state));
  }, [state]);

  // Action Creators
  const addToCart = (product) => {
    dispatch({ type: 'ADD_TO_CART', payload: product });
  };

  const removeFromCart = (id, type) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { id, type } });
  };

  const clearCart = () => {
    dispatch({ type: 'CLEAR_CART' });
  };

  return (
    <CartContext.Provider value={{ cart: state.cart, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom Hook to Use Cart Context
export const useCart = () => useContext(CartContext);
